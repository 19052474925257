import React from "react";
import Logo from "../../images/logo_22.svg";
import Check from "../../images/check.svg";
import './becomeDonor.scss'

const BecomeDonor = () => {
    return (
        <div id="become-donor">
            <div className="container mt-5 mb-5">
                <div className="row">
                    <div className="col d-flex justify-content-center">
                        <img className="w-100 w-sm-75" alt="logo" src={Logo}/>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col">
                        <h1 className="donor-title">Become a Re:factor donor!</h1>
                        <h2>What is Re:factor?</h2>
                        <p>
                        Re:factor was born as a digital conference in 2021. It was the first public conference organized by Axxes, Euricom and HCS Company. Inspiration was taken from their internal conference, Haxx. 
                        Because the world opened up again, we would like to meet you all in person, hence we organize the conference in real life.
                        </p>
                        <p>
                            At Re:factor, we keep a focus on software development in the broadest sense of the term. We do not have a focus on a particular technology stack. 
                            We want to think outside the box when it comes to our sessions. 
                            You can expect to see some sessions about sustainable flight and re-forestation next to hardcore technical talks ranging from Quantum Computing, Cloud Native development and Web 3.
                        </p>
                        <p>
                            And to top it all off, we will end the day with a party. Because above all else, we believe that the people you connect with at a conference are the most important. 
                            Meaningful connections with like-minded people. Come meet us all at Re:factor 2022.
                        </p>
                        <p>
                            National and international speakers will take the stage and share their expertise with the attendees. 
                            The conference will run the entire day, across at least 4 parallel tracks on the 6th of October 2022 at Van der Valk Hotel in Beveren, Belgium.
                        </p>
                        <p>
                            Re:factor tickets are cheap and accessible for anyone who’s interested. 
                            We are using this opportunity to raise money for charity. Axxes, Euricom and HCS Company, the organizing parties, will cover all the costs of the event. 
                            This means that 70% of your donation will make its way to Tree-Nation. The rest will be spent on the actual cost associated with your donation.
                        </p>
                       <h2>What is Tree-Nation?</h2>
                        <p>
                            Tree-Nation is a non-profit organization that allows citizens and companies to plant trees all around the world and offset their CO2 emissions.
                        </p>
                        <p>
                            Their mission is to reforest the world. Planting trees has been proven to be one of the most efficient solutions to fight Climate Change. 
                            Thanks to their reforestation and conservation projects, they help to restore forests, create jobs, support local communities and protect biodiversity.
                        </p>
                        <p>
                            Through the Tree-Nation platform they aim to bring a technological solution to the problem of Deforestation, responsible for about 17% of all Climate Change emissions. 
                            They want to use technology to make tree planting easy and provide support, advice and solutions to citizens and companies to help them transition towards a sustainable future.
                        </p>
                        <p>
                            With 85% of all terrestrial species living in tropical forests, whose existences are intrinsically bound to the forests, their habitat, they care to restore and preserve the biodiversity to help avoid a mass extinction.
                        </p>
                        <h2>What’s in it for your company?</h2>
                        <p>
                        Every donation really helps Tree-Nation deliver their services. But if you are willing to up your donation amount, we are able to give you some visibility at Re:factor
                        </p>
                        <p>
                        <table className="comparison-table">
                                <tr>
                                    <th/>
                                    <th>Bronze</th>
                                    <th>Silver</th>
                                    <th>Gold</th>
                                    <th>Platinum</th>
                                </tr>
                                <tr>
                                    <td>Donor amount (invoice on demand)</td>
                                    <td>€ 500</td>
                                    <td>€ 1000</td>
                                    <td>€ 3000</td>
                                    <td>€ 5000</td>
                                </tr>
                                <tr>
                                    <td>Of which donated to Tree-Nation</td>
                                    <td>€ 350</td>
                                    <td>€ 700</td>
                                    <td>€ 2400</td>
                                    <td>€ 4000</td>
                                </tr>
                                <tr>
                                    <td>Number of included tickets</td>
                                    <td>1</td>
                                    <td>2</td>
                                    <td>4</td>
                                    <td>6</td>
                                </tr>
                                <tr>
                                    <td>Logo on our website (www.re-factor.be)</td>
                                    <td className="check"><img alt="check" src={Check} /></td>
                                    <td className="check"><img alt="check" src={Check} /></td>
                                    <td className="check"><img alt="check" src={Check} /></td>
                                    <td className="check"><img alt="check" src={Check} /></td>
                                </tr>
                                <tr>
                                    <td>Logo on the title slide of every breakout session</td>
                                    <td className="check"><img alt="check" src={Check} /></td>
                                    <td className="check"><img alt="check" src={Check} /></td>
                                    <td className="check"><img alt="check" src={Check} /></td>
                                    <td className="check"><img alt="check" src={Check} /></td>
                                </tr>
                                <tr>
                                    <td>Logo on our email communications</td>
                                    <td className="check"><img alt="check" src={Check} /></td>
                                    <td className="check"><img alt="check" src={Check} /></td>
                                    <td className="check"><img alt="check" src={Check} /></td>
                                    <td className="check"><img alt="check" src={Check} /></td>
                                </tr>
                                <tr>
                                    <td>Shoutout on our social media channels</td>
                                    <td/>
                                    <td className="check"><img alt="check" src={Check} /></td>
                                    <td className="check"><img alt="check" src={Check} /></td>
                                    <td className="check"><img alt="check" src={Check} /></td>
                                </tr>
                                <tr>
                                    <td>Booth at the conference venue</td>
                                    <td/>
                                    <td/>
                                    <td className="check"><img alt="check" src={Check} /></td>
                                    <td className="check"><img alt="check" src={Check} /></td>
                                </tr>
                                <tr>
                                    <td>Breakout session</td>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <td className="check"><img alt="check" src={Check} /></td>
                                </tr>
                            </table>
                        </p>
                        <p>
                            It goes without saying that you’re free to invite all your employees and relations to the
                            event to maximize your visibility and to maximize the value you get out of Re:factor.
                        </p>

                        <p>
                            Do you want to make your donation official, or do you have questions? Get in touch with
                            Hannes through {" "}
                            <a href="mailto:hannes@re-factor.be">hannes@re-factor.be</a>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BecomeDonor;
