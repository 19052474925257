import * as React from "react"
import SEO from "../components/seo/Seo"
import Layout from "../components/layout/Layout"
import NavBar from "../components/navbar/NavBar";
import BecomeDonor from "../components/becomeDonor/BecomeDonor";


const BecomeDonorPage = () => {
    return (
        <Layout>
            <SEO title="Become donor" />
            <NavBar />
            <BecomeDonor />
        </Layout>
    )
}

export default BecomeDonorPage
